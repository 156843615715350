import { useEffect, useRef, useState } from "react";
import { RenderTool } from "../Components/Rendering/types/types";
import initRenderTool, {
  cleanUpRenderTool,
} from "../Components/Rendering/RenderTool";

interface UseRenderTool {
  ref: (ref: HTMLCanvasElement | null) => void;
  tool: RenderTool | null;
}

const useRenderTool: () => UseRenderTool = () => {
  const [tool, setTool] = useState<RenderTool | null>(null);
  const canvasRef = useRef<HTMLCanvasElement>();

  const ref = (ref: HTMLCanvasElement | null) => {
    if (ref && tool === null) {
      canvasRef.current = ref;
      const tool = initRenderTool(ref);
      setTool(tool);
    }
  };

  useEffect(() => {
    return () => {
      if (tool) {
        cleanUpRenderTool(tool);
      }
    };
  }, []);

  return { ref, tool };
};

export default useRenderTool;
