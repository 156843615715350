import { useContext } from "react";
import Button from "../../../Components/Button/Button";
import { AIGenContext } from "../../../Context/AIGen/AIGenContext";

export const AITestPage: React.FC = () => {
  const { rt } = useContext(AIGenContext);

  const handleClick = () => {
    rt?.editor.disable();
  };

  return (
    <div>
      <Button onClick={handleClick}>Test Button</Button>
    </div>
  );
};
