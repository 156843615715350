import { useContext } from "react";
import { AIGenContext } from "../../../Context/AIGen/AIGenContext";

interface AiNavButtonProps {
  children: React.ReactNode | React.ReactNode[];
  route: string;
}

export const AiNavButton: React.FC<AiNavButtonProps> = ({
  children,
  route,
}) => {
  const { route: navRoute, setRoute } = useContext(AIGenContext);

  const handleClick = () => {
    setRoute(route);
  };

  return (
    <button
      onClick={handleClick}
      className={`ai-nav-button ${navRoute === route ? "selected" : ""}`}
    >
      {children}
    </button>
  );
};
