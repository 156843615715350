import useDragDropList from "../../Hooks/useDragDropList";

interface DragOrderListProps {
  list: any[];
  renderCB?: (val: any, i?: number) => JSX.Element;
  onChange: (list: any[]) => void;
  dragDisabled?: boolean;
  id: string;
}

const DragOrderList: React.FC<DragOrderListProps> = ({
  list,
  onChange,
  renderCB,
  id,
  dragDisabled = false,
}) => {
  const { onDrag, onDragOver, onDrop, onDragLeave } = useDragDropList({
    list: list ?? [],
    onChange: (vals: any[]) => onChange(vals),
    useDragNDrop: !dragDisabled,
  });
  return (
    <div>
      {list.map((val, i) => {
        if (renderCB)
          return (
            <div
              onDrag={() => onDrag(i)}
              onDrop={() => onDrop(i)}
              onDragLeave={onDragLeave}
              draggable={!dragDisabled}
              onDragOver={(e) => onDragOver(e, i)}
              key={`${id}-order-${i}${
                typeof val === "string" ? `-${val}` : "-item"
              }`}
            >
              {renderCB(val, i)}
            </div>
          );
        return (
          <div
            onDrag={() => onDrag(i)}
            onDrop={() => onDrop(i)}
            onDragLeave={onDragLeave}
            draggable={!dragDisabled}
            onDragOver={(e) => onDragOver(e, i)}
            key={`${id}-order-${i}${
              typeof val === "string" ? `-${val}` : "-item"
            }`}
          >
            {val}
          </div>
        );
      })}
    </div>
  );
};

export default DragOrderList;
