import { useContext, useEffect, useState } from "react";
import Pagination from "../../Components/Pagination/Pagination";
import useDebounce from "../../Hooks/useDebounce";
import { DataContext } from "../../Context/DataContext";
import Input from "../../Components/Input/Input";
import { capFirstLetter } from "../../Utils/text";

interface BasicEntry {
  entry_date: string;
  content: { entry: string };
  type: string;
  entry_id: number;
}

const journalTypes = [
  { value: "journal", text: "Journal" },
  { value: "spiritual", text: "Spiritual" },
  { value: "thought", text: "Thought" },
];

const baseURL = process.env.REACT_APP_BASE_URL;

const EntriesPage: React.FC = () => {
  const { fetcher } = useContext(DataContext);
  const [entries, setEntries] = useState<BasicEntry[] | undefined>();
  const [page, setPage] = useState(0);
  const [search, setSearch] = useState("");
  const [count, setCount] = useState(10);
  const [totalItems, setTotalItems] = useState(0);
  const [type, setType] = useState<"journal" | "spiritual" | "thought">(
    "spiritual"
  );
  const { debounce } = useDebounce(1000);
  const getEntries = async () => {
    const res = await fetcher(
      `/entries/search?s=${search}&page=${page}&count=${count}&type=${type}`
    );
    if (res?.data?.count) {
      setTotalItems(res.data.count);
    }
    if (res?.data?.data) {
      setEntries(res.data?.data);
    } else {
      console.error("No entries found");
    }
  };

  useEffect(() => {
    getEntries();
  }, [page]);

  useEffect(() => {
    debounce(() => getEntries());
  }, [search, type]);

  if (!entries) return <div>No Entries Found</div>;
  return (
    <div className="thought-page-container">
      <div className="thought-title-container">
        <h1 className="thought-page-title">{capFirstLetter(type)} Thoughts</h1>
        <select
          value={type}
          onChange={(e) => {
            const v = e.target.value;

            if (v === "spiritual" || v === "thought" || v === "journal")
              setType(v);
          }}
          className="journal-select"
        >
          {journalTypes.map((option) => (
            <option key={option.value} value={option.value}>
              {option.text}
            </option>
          ))}
        </select>
        <Input
          style={{ maxWidth: "calc(100vw - 20px)", width: "800px" }}
          id="thought-search-bar"
          value={search}
          placeholder={"search"}
          onChange={(val: string) => setSearch(val)}
        />
      </div>
      {entries.length === 0 && <div>No Entries Found</div>}
      {entries.map((entry: BasicEntry, i) => {
        return (
          <div
            className="thought-container"
            key={`entry-from-${entry.entry_date}-${entry.entry_id}-${i}`}
          >
            <div
              className="thought-date-container"
              style={{ marginTop: "5px" }}
            >
              <span className="thought-date">
                {new Date(entry.entry_date)
                  .toDateString()
                  .split(" ")
                  .splice(1, 3)
                  .join(" ")}
              </span>
            </div>
            <div className="thought-text">{entry.content.entry}</div>
          </div>
        );
      })}
      <Pagination
        currentPage={page + 1}
        pageSize={count}
        totalCount={totalItems}
        id="pagination-thoughts"
        siblingCount={3}
        onPageChange={(nPage: number) => setPage(nPage - 1)}
      />
      <div className="footer-spacing"></div>
    </div>
  );
};
export default EntriesPage;
