import { useContext, useEffect, useState } from "react";
import { AIGenContext } from "../../Context/AIGen/AIGenContext";
import Button from "../Button/Button";
import useWinSize from "../../Hooks/useWinSize";
import { DataContext } from "../../Context/DataContext";
import { CanvasRTLayer } from "../Rendering/types/editor";
import { Slider } from "../Input/Slider";

export const InpaintGen: React.FC = () => {
  const { rt, prompt, setPrompt, setImage } = useContext(AIGenContext);
  const [loading, setLoading] = useState<boolean>(false);
  const { isSmall } = useWinSize();
  const [brushSize, setBrushSize] = useState<number>(25);
  const { fetcher } = useContext(DataContext);

  const setUp = async () => {
    if (rt) {
      const maskIndx = rt.editor.layers.findIndex((l) => l.name === "mask");
      if (maskIndx === -1) {
        const layer = await rt.editor.addLayer({
          layer: {
            type: "canvas",
            name: "mask",
            visibleInExport: false,
            opacity: 0.75,
          },
        });
        rt.editor.selectedLayer = layer;
      } else {
        rt.editor.selectedLayer = maskIndx;
      }
      rt.editor.tool = "paint";
    }
  };

  useEffect(() => {
    setUp();
    return () => {
      if (rt) rt.editor.tool = "none";
    };
  }, []);

  const handleInPaintExport = async () => {
    rt?.editor.disable();
    setLoading(true);
    const img = await rt?.editor.render(true);
    if (img && rt) {
      const sel = rt?.editor.layers?.[rt.editor.selectedLayer];
      if (sel && sel.type === "canvas") {
        const canvas = document.createElement("canvas");
        canvas.width = rt.editor.canvas.width;
        canvas.height = rt.editor.canvas.height;
        const ctx = canvas.getContext("2d");
        if (!ctx) {
          throw new Error("Failed to get 2d context");
        }
        ctx.fillStyle = "#000000";
        ctx.fillRect(0, 0, canvas.width, canvas.height);
        ctx.drawImage(sel.canvas, 0, 0);
        const mask = canvas.toDataURL("image/png");
        console.log(mask);
        const result = await fetcher("/ai/image-inpaint", {
          method: "POST",
          body: { prompt, mask, image: img },
        });
        if (result?.data?.hostedImageURL) {
          setImage(result.data.hostedImageURL);
        }
        console.log(result);
      }
    }
    setLoading(false);
    rt?.editor.enable();
  };
  const clearInPaintMask = async () => {
    const maskIndx = rt?.editor.layers.findIndex((l) => l.name === "mask");
    if (maskIndx !== -1 && maskIndx) {
      if (rt?.editor.layers[maskIndx].type === "canvas") {
        const layer = rt.editor.layers[maskIndx] as CanvasRTLayer;
        layer.ctx.clearRect(
          0,
          0,
          rt.editor.canvas.width,
          rt.editor.canvas.height
        );
        rt.editor.rerender = true;
        rt.r.render();
      }
    }
  };
  return (
    <div className="igen-settings-container">
      <div className="igen-section">
        <Button onClick={clearInPaintMask}>Clear</Button>
      </div>
      <div className="igen-section">
        <h4 className="m-0 mv-5">Prompt</h4>
        <textarea
          className="text-area-ai"
          value={prompt}
          onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
            setPrompt(e.target.value)
          }
        ></textarea>
      </div>
      <div className="igen-section">
        <Slider
          min={5}
          max={100}
          step={5}
          label={"Brush Size"}
          onChange={(v) => {
            setBrushSize(v);
            if (rt) rt.editor.toolData.brushSize = v;
          }}
          value={brushSize}
        />
      </div>
      <div className="igen-section">
        <Button
          onClick={handleInPaintExport}
          disabled={loading}
          className={`mv-5 ${isSmall ? "" : "button-new"}`}
        >
          Submit
        </Button>
      </div>
    </div>
  );
};
