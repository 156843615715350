export function imageToBase64(image: HTMLImageElement): string {
  const canvas = document.createElement("canvas");
  canvas.width = image.width;
  canvas.height = image.height;
  const ctx = canvas.getContext("2d");
  if (ctx) {
    ctx.drawImage(image, 0, 0);
  }
  return canvas.toDataURL("image/png");
}

export function base64ToImage(base64: string): Promise<HTMLImageElement> {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => resolve(img);
    img.onerror = reject;
    img.src = base64;
  });
}

export async function loadImageURL(url: string) {
  return new Promise<HTMLImageElement>((resolve, reject) => {
    const img = new Image();
    img.crossOrigin = "Anonymous"; // Set crossOrigin before src
    img.onload = () => resolve(img);
    img.onerror = reject;
    //You need to cache bust or else it will not work. Especially if the images are loaded else where.
    img.src = `${url}?cb=${Date.now()}`;
  });
}
