import { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { DataContext } from "../../Context/DataContext";
import useNavLoc from "../../Hooks/Nav/useNavLoc";
import usePermissionsNav from "../../Hooks/Nav/usePermissionsNav";
import HamburgerNav from "./HamburgerNav";
import useBaseRouteLinks from "../../Hooks/Nav/useBaseRouteLinks";

const MobileNav: React.FC = () => {
  const { links } = usePermissionsNav();
  const { baseLinks } = useBaseRouteLinks({ useHomeRoute: true });
  const { isBaseRoute, isProjectRoute, isUnlockedThoughts } = useNavLoc();
  return (
    <>
      <div className="relative">
        <div className="nav-container">
          <span></span>
          <div className="nav-header">
            {isBaseRoute
              ? "Home"
              : isProjectRoute
              ? "Projects"
              : isUnlockedThoughts
              ? "Thoughts"
              : "Not Found"}
          </div>
          <span></span>
        </div>

        <HamburgerNav
          links={[
            ...(isBaseRoute ? baseLinks : [{ link: "/", text: "Home" }]),
            ...links,
          ]}
        />
      </div>
      <div style={{ height: "45px" }}></div>
    </>
  );
};

export default MobileNav;
