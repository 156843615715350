// MATRIX.TS

export class Matrix {
  a: number;
  b: number;
  c: number;
  d: number;
  e: number;
  f: number;

  constructor(a = 1, b = 0, c = 0, d = 1, e = 0, f = 0) {
    this.a = a;
    this.b = b;
    this.c = c;
    this.d = d;
    this.e = e;
    this.f = f;
  }

  /**
   * Multiplies the current matrix with another matrix.
   * @param matrix - The matrix to multiply with.
   * @returns The current Matrix instance after multiplication.
   */
  multiply(matrix: Matrix): this {
    const a = this.a * matrix.a + this.c * matrix.b;
    const b = this.b * matrix.a + this.d * matrix.b;
    const c = this.a * matrix.c + this.c * matrix.d;
    const d = this.b * matrix.c + this.d * matrix.d;
    const e = this.a * matrix.e + this.c * matrix.f + this.e;
    const f = this.b * matrix.e + this.d * matrix.f + this.f;

    this.a = a;
    this.b = b;
    this.c = c;
    this.d = d;
    this.e = e;
    this.f = f;

    return this;
  }

  /**
   * Inverts the current matrix.
   * @returns A new Matrix instance representing the inverse, or null if not invertible.
   */
  invert(): Matrix | null {
    const det = this.a * this.d - this.b * this.c;
    if (det === 0) return null;
    return new Matrix(
      this.d / det,
      -this.b / det,
      -this.c / det,
      this.a / det,
      (this.c * this.f - this.d * this.e) / det,
      (this.b * this.e - this.a * this.f) / det
    );
  }

  /**
   * Applies the matrix transformation to a point.
   * @param x - The x-coordinate of the point.
   * @param y - The y-coordinate of the point.
   * @returns An object containing the transformed x and y coordinates.
   */
  applyToPoint(x: number, y: number): { x: number; y: number } {
    return {
      x: this.a * x + this.c * y + this.e,
      y: this.b * x + this.d * y + this.f,
    };
  }

  /**
   * Applies a translation transformation to the current matrix in place.
   * @param tx - The translation distance along the x-axis.
   * @param ty - The translation distance along the y-axis.
   * @returns The current Matrix instance after translation.
   */
  translate(tx: number, ty: number): this {
    this.e += this.a * tx + this.c * ty;
    this.f += this.b * tx + this.d * ty;
    return this;
  }

  /**
   * Applies a scaling transformation to the current matrix in place.
   * @param sx - The scaling factor along the x-axis.
   * @param sy - The scaling factor along the y-axis. If not provided, sy defaults to sx.
   * @returns The current Matrix instance after scaling.
   */
  scale(sx: number, sy: number = sx): this {
    this.a *= sx;
    this.b *= sx;
    this.c *= sy;
    this.d *= sy;
    return this;
  }

  /**
   * Creates a translation matrix for the given distances.
   * @param tx - The translation distance along the x-axis.
   * @param ty - The translation distance along the y-axis.
   * @returns A new Matrix instance representing the translation.
   */
  static createTranslation(tx: number, ty: number): Matrix {
    return new Matrix(1, 0, 0, 1, tx, ty);
  }

  /**
   * Creates a scaling matrix for the given factors.
   * @param sx - The scaling factor along the x-axis.
   * @param sy - The scaling factor along the y-axis. If not provided, sy defaults to sx.
   * @returns A new Matrix instance representing the scaling.
   */
  static createScaling(sx: number, sy?: number): Matrix {
    return new Matrix(sx, 0, 0, sy !== undefined ? sy : sx, 0, 0);
  }
}
