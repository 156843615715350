import { RTLayer } from "../../Rendering/types/editor";
import { ReactComponent as ImageIcon } from "../../../SVGs/image-regular.svg";
import { ReactComponent as MaskIcon } from "../../../SVGs/mask-icon.svg";
import { ReactComponent as FrameIcon } from "../../../SVGs/frame.svg";
import { ReactComponent as ScissorsIcon } from "../../../SVGs/scissors-solid.svg";
import { ReactComponent as LayerIcon } from "../../../SVGs/layer-group-solid.svg";
import { useContext, useEffect, useState } from "react";
import { AIGenContext } from "../../../Context/AIGen/AIGenContext";

interface LayerCardProps {
  layer: RTLayer;
  selected: boolean;
  i: number;
}

export const LayerCard: React.FC<LayerCardProps> = ({ layer, selected, i }) => {
  const { rt, triggerState } = useContext(AIGenContext);

  const handleClick = (e: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
    e.preventDefault();
    if (rt) {
      rt.editor.selectedLayer = i;
      triggerState();
    }
  };
  const [name, setName] = useState(layer.name);

  useEffect(() => {
    layer.name = name;
  }, [name]);
  const [renaming, setRenaming] = useState(false);
  return (
    <div className="ai-layer-card-container">
      <div className="ai-layer-card">
        <div className="ai-layer-title-container">
          <div className={`left-bar ${selected ? "selected" : ""}`}></div>
          <div className="ai-layer-icon">
            {layer.type === "image" && <ImageIcon />}
            {layer.type === "canvas" && <FrameIcon />}
          </div>
          <input
            className="ai-layer-input"
            readOnly={!renaming}
            onBlur={() => setRenaming(false)}
            onClick={handleClick}
            onDoubleClick={() => setRenaming(true)}
            onChange={(e) => setName(e.target.value)}
            value={name}
          ></input>
        </div>
      </div>
    </div>
  );
};
