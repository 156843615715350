import { useEffect, useRef, useState } from "react";
import {
  GroupInput,
  ParticleImageInput,
  ParticleInput,
  ParticleTextInput,
} from "./types/particleReader";
import { renderImageToCtx, renderTextToCtx } from "./canvasReader/renderFuncs";
import { getCanvasPoints } from "./canvasReader/canvasReading";
import { DPIRef, DefaultedWrapperOptions } from "./types";
import { AddFromPoints } from "./hooks/useGroupController";
import { shuffle } from "./utils/lists";

interface CanvasReaderProps {
  width: number;
  height: number;
  settings: DefaultedWrapperOptions;
  addFromPoints: AddFromPoints;
  dpiRef: React.MutableRefObject<DPIRef>;
}

interface UseCanvasReader {
  addInputs: (gInputs: GroupInput) => void;
  handleCanvasRef: (ref: HTMLCanvasElement) => void;
  hasCTX: boolean;
}
const useCanvasReader: (props: CanvasReaderProps) => UseCanvasReader = ({
  width,
  height,
  settings,
  addFromPoints,
  dpiRef,
}) => {
  const canvasRef = useRef<HTMLCanvasElement>();
  const [ctx, setCtx] = useState<CanvasRenderingContext2D>();
  const sizeRef = useRef<{ width: number; height: number }>({
    width,
    height,
  });
  const handleCanvasRef = (ref: HTMLCanvasElement | null) => {
    if (ref) {
      const refCtx = ref.getContext("2d", { willReadFrequently: true });
      canvasRef.current = ref;
      canvasRef.current.width = window.innerWidth * dpiRef.current.dpi;
      canvasRef.current.height = window.innerHeight * dpiRef.current.dpi;
      sizeRef.current.width = canvasRef.current.width;
      sizeRef.current.height = canvasRef.current.height;
      if (refCtx) {
        setCtx(refCtx);
      }
    }
  };

  useEffect(() => {
    const adjustSize = () => {
      if (ctx && canvasRef.current) {
        canvasRef.current.width = window.innerWidth * dpiRef.current.dpi;
        canvasRef.current.height = window.innerHeight * dpiRef.current.dpi;
        sizeRef.current.width = canvasRef.current.width;
        sizeRef.current.height = canvasRef.current.height;
      }
    };
    window.addEventListener("resize", adjustSize);
    return () => window.removeEventListener("resize", adjustSize);
  }, [canvasRef, ctx]);

  const updateCTXForProcessing = (inputs: ParticleInput[]) => {
    let renderedToCanvas = false;
    if (ctx) {
      ctx.clearRect(0, 0, sizeRef.current.width, sizeRef.current.height);
      for (let l = 0; l < inputs.length; l++) {
        const renderTask: any = inputs[l];
        //if the user wants text to be the thing that particles show then get the positions from text
        if (renderTask?.text) {
          const input = renderTask as ParticleTextInput;
          renderTextToCtx(
            input,
            ctx,
            sizeRef.current.width,
            sizeRef.current.height,
            dpiRef.current.dpi
          );
          renderedToCanvas = true;
          //if the user wants an image to be the thing we render
        } else if (renderTask?.image) {
          const input = renderTask as ParticleImageInput;
          renderImageToCtx(
            input,
            ctx,
            sizeRef.current.width,
            sizeRef.current.height,
            dpiRef.current.dpi
          );
          renderedToCanvas = true;
        }
      }
    }
    return renderedToCanvas;
  };
  const addInputs = (gInput: GroupInput) => {
    if (ctx) {
      const { inputs } = gInput;
      if (inputs.length > 0) {
        //iterate through all of the inputs passed in and render them to the canvas
        const renderedToCanvas = updateCTXForProcessing(inputs);
        if (renderedToCanvas) {
          // console.log(sizeRef.current.width);
          const image = ctx.getImageData(
            0,
            0,
            sizeRef.current.width,
            sizeRef.current.height
          ).data;
          const pointData = getCanvasPoints(
            image,
            sizeRef.current.width,
            sizeRef.current.height,
            dpiRef.current.dpi,
            Math.min(
              Math.max(gInput?.resPerc ?? settings.resolutionPercent, 5),
              100
            ),
            gInput?.removeWhite ?? false
          );
          ctx.clearRect(0, 0, sizeRef.current.width, sizeRef.current.height);
          if (gInput.shufflePoints)
            pointData.points = shuffle(pointData.points);
          addFromPoints(pointData, gInput);
        }
      }
    }
  };

  return {
    addInputs,
    handleCanvasRef,
    hasCTX: ctx !== undefined && ctx !== null,
  } as UseCanvasReader;
};

export default useCanvasReader;
