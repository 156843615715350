import { RTEaddLayer } from "./editor/addLayer";
import { RTEClearAllLayers } from "./editor/clearLayers";
import { RTEDisable, RTEEnable } from "./editor/disable";
import { RTERender } from "./editor/render";
import { RTEResize } from "./editor/resize";
import { RTEInitBasicTools } from "./editor/tools/initBasics";
import { InitRTEditorOptions, RTEditor } from "./types/editor";
import { RenderTool } from "./types/types";

export const initRTEditorProps: (props?: InitRTEditorOptions) => RTEditor = (
  props = { width: 1024, height: 1024 }
) => {
  const canvas = document.createElement("canvas");
  canvas.width = props?.width ?? 1024;
  canvas.height = props?.height ?? 1024;
  const ctx = canvas.getContext("2d");
  if (!ctx) {
    throw new Error("Failed to get 2d context");
  }
  const result64 = canvas.toDataURL("image/png");

  return {
    canvas,
    ctx,
    result64,
    render: async () => {},
    layers: [],
    layerCnt: 0,
    selectedLayer: -1,
    tool: "none",
    toolData: {},
    toolFuncs: {},
    rerender: true,
    disabled: false,
    disable: () => {},
    enable: () => {},
    addLayer: async () => -1,
    clearAllLayers: () => {},
    resize: () => {},
  };
};

export const initRTEditor = (rt: RenderTool) => {
  rt.editor.resize = RTEResize(rt);
  rt.editor.render = RTERender(rt);
  rt.editor.addLayer = RTEaddLayer(rt);
  rt.editor.clearAllLayers = RTEClearAllLayers(rt);
  rt.editor.toolFuncs = RTEInitBasicTools(rt);
  rt.editor.enable = RTEEnable(rt);
  rt.editor.disable = RTEDisable(rt);
};
