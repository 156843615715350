import { RenderTool } from "./types/types";
import { Matrix } from "./utils/math/matrix";
import { mouseBounds } from "./utils/mouseBounds";

export const initRTMouse = (rt: RenderTool) => {
  const onMouseMove = (e: MouseEvent) => {
    if (e.target === rt.canvas) {
      const rect = rt.canvas.getBoundingClientRect();
      //update the mouse attributes
      rt.mouse.lastX = rt.mouse.x;
      rt.mouse.lastY = rt.mouse.y;
      rt.mouse.lastXCameraSpace = rt.mouse.xCameraSpace;
      rt.mouse.lastYCameraSpace = rt.mouse.yCameraSpace;
      const x = e.clientX - rect.left;
      const y = e.clientY - rect.top;
      rt.mouse.x = x;
      rt.mouse.y = y;

      const inverseTransform = rt.camera.matrix.invert();
      if (inverseTransform) {
        //get the mouse position in camera space
        const cameraSpacePoint = inverseTransform.applyToPoint(
          //since the dpi might be higher or the canvas might be scaled we need to adjust the mouse position
          x * (rt.canvas.width / rt.canvas.clientWidth),
          y * (rt.canvas.height / rt.canvas.clientHeight)
        );
        rt.mouse.xCameraSpace = cameraSpacePoint.x;
        rt.mouse.yCameraSpace = cameraSpacePoint.y;
      }
      //move the mouse with the middle mouse button
      if (e.buttons === 4) {
        rt.mouse.isMiddleDown = true;
        rt.camera.x -=
          (rt.mouse.x / rt.camera.zoom - rt.mouse.lastX / rt.camera.zoom) *
          rt.options.dpi;
        rt.camera.y -=
          (rt.mouse.y / rt.camera.zoom - rt.mouse.lastY / rt.camera.zoom) *
          rt.options.dpi;
        //limit the camera movement to bounds
        mouseBounds(rt);
        //rerender the view
        rt.r.render();
      } else {
        rt.mouse.isMiddleDown = false;
      }
      if (rt.mouse.isLeftDown && !rt.editor.disabled) {
        rt.editor.toolFuncs[rt.editor.tool]?.(rt, e);
      }
    }
  };

  const onMouseWheel = (e: WheelEvent) => {
    if (e.target === rt.canvas) {
      e.preventDefault();
      const delta = e.deltaY > 0 ? -rt.camera.zoom / 8 : rt.camera.zoom / 8;
      rt.camera.zoom = Math.max(0.1, rt.camera.zoom + delta);

      //get the mouse position in the new camera space, then move the camera so that the new zoomed mouse pos is in the same position, this enables zooming towards a point
      const m = new Matrix();
      m.translate(rt.canvas.width / 2, rt.canvas.height / 2)
        .scale(rt.camera.zoom, rt.camera.zoom)
        .translate(-rt.camera.x, -rt.camera.y);
      const inverseTransform = m.invert();
      if (inverseTransform) {
        const ncp = inverseTransform.applyToPoint(
          rt.mouse.x * (rt.canvas.width / rt.canvas.clientWidth),
          rt.mouse.y * (rt.canvas.height / rt.canvas.clientHeight)
        );
        rt.camera.x -= ncp.x - rt.mouse.xCameraSpace;
        rt.camera.y -= ncp.y - rt.mouse.yCameraSpace;
      }

      mouseBounds(rt);
      rt.r.render();
    }
  };

  const onMouseDown = (e: MouseEvent) => {
    if (e.target === rt.canvas) {
      if (e.button === 0) {
        rt.mouse.isLeftDown = true;
        if (!rt.editor.disabled) {
          rt.editor.toolFuncs[rt.editor.tool]?.(rt, e);
        }
      }
      if (e.button === 2) {
        rt.mouse.isRightDown = true;
      }
    }
  };

  const onMouseUp = (e: MouseEvent) => {
    if (e.button === 0) {
      rt.mouse.isLeftDown = false;
    }
    if (e.button === 2) {
      rt.mouse.isRightDown = false;
    }
    rt.r.render();
  };

  rt.listeners.mouseMove = onMouseMove;
  rt.listeners.mouseWheel = onMouseWheel;
  rt.listeners.mouseDown = onMouseDown;
  rt.listeners.mouseUp = onMouseUp;

  window.addEventListener("mousemove", rt.listeners.mouseMove);
  window.addEventListener("wheel", rt.listeners.mouseWheel, { passive: false });
  window.addEventListener("mousedown", rt.listeners.mouseDown);
  window.addEventListener("mouseup", rt.listeners.mouseUp);
};

export const cleanupRTMouse = (rt: RenderTool) => {
  window.removeEventListener("mousemove", rt.listeners.mouseMove);
  window.removeEventListener("wheel", rt.listeners.mouseWheel);
  window.removeEventListener("mousedown", rt.listeners.mouseDown);
  window.removeEventListener("mouseup", rt.listeners.mouseUp);
};
