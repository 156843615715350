import { useState } from "react";
import { Link } from "react-router-dom";
import Toggle from "../Input/Toggle";
import ParticleController from "../Particles/ParticleController";

interface HamburgerNavProps {
  links: { link?: string; text: string; action?: () => void }[];
}

const HamburgerNav: React.FC<HamburgerNavProps> = ({ links }) => {
  const [navOpened, setNavOpened] = useState(false);
  const opened = navOpened ? "opened" : "";
  const closeNav = { onClick: () => setNavOpened(false) };
  return (
    <div className="relative">
      <div className={`small-nav-container ${opened}`}>
        <div className="nav-item-container">
          {links.map((link) => {
            if (link?.action)
              return (
                <div onClick={link?.action} className="link-no-dec nav-item">
                  {link.text}
                </div>
              );
            if (link?.link)
              return (
                <Link
                  key={`hamburger-nav-link-${link.text}`}
                  {...closeNav}
                  to={link.link}
                  className="link-no-dec nav-item"
                >
                  {link.text}
                </Link>
              );
          })}
          <ParticleController />
        </div>
        <div
          className="hamburger-container"
          onClick={() => setNavOpened(!navOpened)}
        >
          <div className={`hamburger ${opened}`}></div>
          <div className={`hamburger hidden ${opened}`}></div>
          <div className={`hamburger flipped ${opened}`}></div>
        </div>
      </div>
      {opened && (
        <div
          className={`nav-overlay ${opened}`}
          onClick={() => setNavOpened(false)}
        ></div>
      )}
    </div>
  );
};

export default HamburgerNav;
