import { useMemo } from "react";

const setWindowHash = (hash: string) => {
  window.location.hash = `#${hash}`;
};

interface UseBaseRouteLinksProps {
  useHomeRoute?: boolean;
}

const useBaseRouteLinks = (props?: UseBaseRouteLinksProps) => {
  const baseLinks = useMemo(() => {
    const baseLinks = [];
    if (props?.useHomeRoute) {
      baseLinks.push({
        link: "/",
        text: "Home",
      });
    }
    baseLinks.push({
      action: () => setWindowHash("project-section"),
      text: "Projects",
    });
    baseLinks.push({
      action: () => setWindowHash("about-section"),
      text: "About",
    });
    baseLinks.push({
      action: () => setWindowHash("experience-section"),
      text: "Experience",
    });
    baseLinks.push({
      action: () => setWindowHash("contact-section"),
      text: "Contact",
    });
    return baseLinks;
  }, []);

  return { baseLinks };
};

export default useBaseRouteLinks;
