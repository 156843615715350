import { saveAs } from "file-saver";
const baseURL = process.env.REACT_APP_BASE_URL;

export const downloadImage = async (imageUrl: string, fileName: string) => {
  saveAs(imageUrl, fileName);
};

export const downloadBase64Image = async (image: string, fileName: string) => {
  saveAs(image, fileName);
};

export const copyToClipboard = async (text: string) => {
  try {
    await navigator.clipboard.writeText(text);
    // Optional: Add success feedback
    console.log("Copied to clipboard");
  } catch (err) {
    console.error("Failed to copy:", err);
  }
};
