import { RenderTool } from "../../types/types";

export const RTEPaintOnCanvasTool = (rt: RenderTool, e?: MouseEvent) => {
  if (
    rt.editor.selectedLayer !== -1 &&
    rt.editor.layers?.[rt.editor.selectedLayer] &&
    rt.editor.layers[rt.editor.selectedLayer].type === "canvas"
  ) {
    const layer = rt.editor.layers[rt.editor.selectedLayer];
    if (layer.type === "canvas") {
      rt.editor.rerender = true;

      if (rt.editor.toolData?.brushSize === undefined)
        rt.editor.toolData.brushSize = 50;
      if (rt.editor.toolData?.brushColor === undefined) {
        rt.editor.toolData.brushColor = "#FFFFFF";
      }
      layer.ctx.save();
      rt.ctx.fillStyle = rt.editor.toolData.brushColor;
      if (e?.altKey) {
        layer.ctx.globalCompositeOperation = "destination-out";
        layer.ctx.fillStyle = "rgba(0,0,0,1)"; // The color doesn't matter in 'destination-out'
        rt.ctx.fillStyle = "#000000";
      } else {
        layer.ctx.globalCompositeOperation = "source-over";
        layer.ctx.fillStyle = rt.editor.toolData.brushColor;
      }

      if (rt.editor.toolData?.brushType === "square") {
        layer.ctx.fillRect(
          rt.mouse.xCameraSpace - rt.editor.toolData?.brushSize / 2,
          rt.mouse.yCameraSpace - rt.editor.toolData?.brushSize / 2,
          rt.editor.toolData?.brushSize,
          rt.editor.toolData?.brushSize
        );

        //render onto the actual display canvas as well for visual purpses and performance, it will render the actual results when the camera or viewport is moved

        rt.ctx.fillRect(
          rt.mouse.x - (rt.editor.toolData?.brushSize / 2) * rt.camera.zoom,
          rt.mouse.y - (rt.editor.toolData?.brushSize / 2) * rt.camera.zoom,
          rt.editor.toolData?.brushSize * rt.camera.zoom,
          rt.editor.toolData?.brushSize * rt.camera.zoom
        );
      } else {
        // Draw circle on the layer's canvas
        // layer.ctx.lineWidth = 0;
        // layer.ctx.beginPath();
        // layer.ctx.arc(
        //   rt.mouse.xCameraSpace,
        //   rt.mouse.yCameraSpace,
        //   rt.editor.toolData.brushSize / 2,
        //   0,
        //   Math.PI * 2
        // );
        // layer.ctx.fill();
        layer.ctx.save();
        {
          layer.ctx.strokeStyle = rt.editor.toolData.brushColor;
          layer.ctx.lineCap = "round";
          layer.ctx.lineJoin = "round";
          layer.ctx.lineWidth = rt.editor.toolData.brushSize;
          layer.ctx.beginPath();
          layer.ctx.moveTo(
            rt.mouse.lastXCameraSpace,
            rt.mouse.lastYCameraSpace
          );
          layer.ctx.lineTo(rt.mouse.xCameraSpace, rt.mouse.yCameraSpace);
          layer.ctx.stroke();
        }
        layer.ctx.restore();

        // Render circle on the display canvas
        rt.editor.rerender = true;
        // rt.ctx.beginPath();
        // rt.ctx.arc(
        //   rt.mouse.x,
        //   rt.mouse.y,
        //   (rt.editor.toolData.brushSize / 2) * rt.camera.zoom,
        //   0,
        //   Math.PI * 2
        // );
        // rt.ctx.fill();
        rt.ctx.save();
        {
          rt.ctx.strokeStyle = rt.editor.toolData.brushColor;
          rt.ctx.lineCap = "round";
          rt.ctx.lineJoin = "round";
          rt.ctx.lineWidth = rt.editor.toolData.brushSize * rt.camera.zoom;

          rt.ctx.beginPath();
          rt.ctx.moveTo(rt.mouse.lastX, rt.mouse.lastY);
          rt.ctx.lineTo(rt.mouse.x, rt.mouse.y);
          rt.ctx.stroke();
        }
        rt.ctx.restore();
      }
      layer.ctx.restore();
    }
  }
};
