import { useState } from "react";

interface ToggleProps {
    id?: string;
    onChange: (v: boolean) => void;
    checked: boolean;
}


const Toggle: React.FC<ToggleProps> = ({id="toggle", checked, onChange}) => {

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        // setToggle(event.target.checked);
        onChange(event.target.checked)
    };
    return <label htmlFor={id} className={`toggle-container ${checked ? "true" : "false"}`}>
        <input  checked={checked} 
                onChange={handleChange}  className="toggle-switch" type="checkbox" id={id} />
    </label>
}

export default Toggle;