import { RenderTool } from "./types/types";
import { drawImageWithRoundedCorners } from "./utils/ctx/drawImageRoundedBorder";
import { drawRoundedRect } from "./utils/ctx/drawRoundedRect";
import { Matrix } from "./utils/math/matrix";

export const initRTRenderer = (rt: RenderTool) => {
  const render = async () => {
    if (rt.editor.rerender) {
      rt.editor.rerender = false;
      await rt.editor.render();
    }
    rt.ctx.clearRect(0, 0, rt.canvas.width, rt.canvas.height);

    //make it so images show up are pixelated
    rt.ctx.imageSmoothingEnabled = false;
    rt.ctx.imageSmoothingQuality = "low";

    rt.ctx.save();
    rt.camera.matrix = new Matrix();
    rt.camera.matrix
      .translate(rt.canvas.width / 2, rt.canvas.height / 2)
      .scale(rt.camera.zoom, rt.camera.zoom)
      .translate(-rt.camera.x, -rt.camera.y);

    rt.ctx.setTransform(
      rt.camera.matrix.a,
      rt.camera.matrix.b,
      rt.camera.matrix.c,
      rt.camera.matrix.d,
      rt.camera.matrix.e,
      rt.camera.matrix.f
    );

    drawImageWithRoundedCorners(
      rt.ctx,
      rt.editor.canvas,
      0,
      0,
      rt.editor.canvas.width,
      rt.editor.canvas.height,
      20,
      2,
      "#c8c8c8"
    );

    if (rt.editor.disabled) {
      const opacity = Math.sin(Date.now() / 500) * 0.25 + 0.5;
      rt.ctx.fillStyle = `rgba(242, 242, 242, ${opacity})`;
      drawRoundedRect(
        rt.ctx,
        0,
        0,
        rt.editor.canvas.width,
        rt.editor.canvas.height,
        20
      );
    }
    rt.ctx.restore();
    if (rt.editor.disabled) {
      requestAnimationFrame(render);
    }
  };

  rt.r.render = render;
};
