interface SliderProps {
  onChange: (value: number) => void;
  value: number;
  min: number;
  max: number;
  step: number;
  label: string;
  disabled?: boolean;
  className?: string;
  style?: React.CSSProperties;
}

export const Slider: React.FC<SliderProps> = ({
  label,
  min,
  max,
  value,
  step,
  onChange,
}) => {
  return (
    <div className="slider-container">
      {label && <label className="slider-label">{label}</label>}
      <div className="slider-content">
        <input
          type="range"
          min={min}
          step={step}
          max={max}
          value={value}
          onChange={(e) => onChange(parseFloat(e.target.value))}
          className="slider"
        />
        <span className="slider-value">{value}</span>
      </div>
    </div>
  );
};
