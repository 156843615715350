import { InitRTEditorOptions } from "../types/editor";
import { RenderTool } from "../types/types";

export const RTEResize = (rt: RenderTool) => (props: InitRTEditorOptions) => {
  rt.editor.canvas.width = props?.width ?? 1024;
  rt.editor.canvas.height = props?.height ?? 1024;
  for (let i = 0; i < rt.editor.layers.length; i++) {
    const layer = rt.editor.layers[i];
    if (
      layer.type === "canvas" &&
      (layer.canvas.width !== (props?.width ?? 1024) ||
        layer.canvas.height !== (props?.height ?? 1024))
    ) {
      layer.canvas.width = props?.width ?? 1024;
      layer.canvas.height = props?.height ?? 1024;
    }
  }
};
