import { useCallback, useContext } from "react";
import { AIGenContext } from "../../../Context/AIGen/AIGenContext";
import { LayerCard } from "./LayerCard";
import DragOrderList from "../../Input/DragOrderList";
import CollapsableTitle from "../../Collapsable/CollapsableTitle";
import useContextMenu from "../../../Hooks/Input/useContextMenu";
import PaintSection from "../tools/PaintSection";

export const LayersSection: React.FC = () => {
  const { rt, stateTrigger, triggerState } = useContext(AIGenContext);
  const { ContextMenu, onContextMenu } = useContextMenu();

  const renderCB = useCallback(
    (layer: any, i?: number) => {
      return (
        <div
          onContextMenu={(e: React.MouseEvent) =>
            onContextMenu(e, [
              {
                element: "Delete",
                onClick: async () => {
                  if (rt) {
                    rt.editor.layers = rt.editor.layers.filter(
                      (_, index) => index !== i
                    );
                    rt.editor.rerender = true;
                    rt.r.render();
                    triggerState();
                  }
                },
              },
              {
                element: "Add Layer Below",
                onClick: async () => {
                  if (rt) {
                    rt.editor.addLayer({
                      layer: {
                        type: "canvas",
                        name: "layer-" + rt.editor.layerCnt,
                      },
                      index: (i ?? 0) + 1,
                    });
                    rt.editor.rerender = true;
                    rt.r.render();
                    triggerState();
                  }
                },
              },
            ])
          }
        >
          <LayerCard
            key={layer?.name + "-" + i}
            i={i ?? -1}
            layer={layer}
            selected={rt?.editor.selectedLayer === i}
          />
        </div>
      );
    },
    [rt]
  );

  const handleLayerChange = (list: any) => {
    if (rt) {
      rt.editor.layers = list;
      rt.editor.rerender = true;
      rt.r.render();
      triggerState();
    }
  };

  return (
    <div className="igen-settings-container">
      <div className="igen-section">
        <ContextMenu />
        <CollapsableTitle
          style={{ padding: "0px", marginTop: "10px" }}
          containerStyle={{ padding: "0px" }}
          titleStyle={{ fontSize: "16px" }}
          collapsableStyle={{ padding: "0px", marginTop: "10px", gap: "10px" }}
          title={"Layers"}
          useSeperator={false}
        >
          <div
            style={{ display: "flex", flexDirection: "column", gap: "10px" }}
          >
            <DragOrderList
              renderCB={renderCB}
              list={rt?.editor.layers ?? []}
              id="layers-section"
              onChange={handleLayerChange}
            />
          </div>
        </CollapsableTitle>
      </div>
      <div className="igen-section">
        <CollapsableTitle
          style={{ padding: "0px", marginTop: "10px" }}
          containerStyle={{ padding: "0px" }}
          titleStyle={{ fontSize: "16px" }}
          collapsableStyle={{ padding: "0px", marginTop: "10px", gap: "10px" }}
          title={"Tools"}
          useSeperator={false}
        >
          <div
            style={{ display: "flex", flexDirection: "column", gap: "10px" }}
          >
            <PaintSection />
          </div>
        </CollapsableTitle>
      </div>
    </div>
  );
};
