export function drawImageWithRoundedCorners(
  ctx: CanvasRenderingContext2D,
  img: HTMLImageElement | ImageBitmap | HTMLCanvasElement,
  x: number,
  y: number,
  width: number,
  height: number,
  radius: number,
  borderWidth?: number,
  borderColor?: string
) {
  // Ensure radius isn't too large
  radius = Math.min(radius, width / 2, height / 2);

  ctx.save(); // Save the current state
  ctx.beginPath();
  ctx.moveTo(x + radius, y);
  ctx.lineTo(x + width - radius, y);
  ctx.arcTo(x + width, y, x + width, y + radius, radius);
  ctx.lineTo(x + width, y + height - radius);
  ctx.arcTo(x + width, y + height, x + width - radius, y + height, radius);
  ctx.lineTo(x + radius, y + height);
  ctx.arcTo(x, y + height, x, y + height - radius, radius);
  ctx.lineTo(x, y + radius);
  ctx.arcTo(x, y, x + radius, y, radius);
  ctx.closePath();
  ctx.clip(); // Clip to the rounded rectangle path

  // Draw the image within the clipped area
  ctx.drawImage(img, x, y, width, height);

  ctx.restore(); // Restore to the original state

  // Optional: Draw the border
  if (borderWidth && borderColor) {
    ctx.beginPath();
    ctx.moveTo(x + radius, y);
    ctx.lineTo(x + width - radius, y);
    ctx.arcTo(x + width, y, x + width, y + radius, radius);
    ctx.lineTo(x + width, y + height - radius);
    ctx.arcTo(x + width, y + height, x + width - radius, y + height, radius);
    ctx.lineTo(x + radius, y + height);
    ctx.arcTo(x, y + height, x, y + height - radius, radius);
    ctx.lineTo(x, y + radius);
    ctx.arcTo(x, y, x + radius, y, radius);
    ctx.closePath();

    ctx.lineWidth = borderWidth;
    ctx.strokeStyle = borderColor;
    ctx.stroke();
  }
}
