import { ImageRTLayer, RTLayer, RTObjectBase } from "../types/editor";
import { RenderTool } from "../types/types";
import { loadImageURL } from "../utils/image";

interface Canvas extends RTObjectBase {
  type: "canvas";
}

export interface RTAddLayerProps {
  layer: ImageRTLayer | Canvas;
  index?: number;
}

export const RTEaddLayer =
  (rt: RenderTool) => async (props: RTAddLayerProps) => {
    rt.editor.layerCnt++;
    rt.editor.rerender = true;
    if (props.layer.type === "image") {
      if (typeof props.layer.image === "string") {
        const layer = props.layer;
        const img = await loadImageURL(props.layer.image);
        const nLayer: RTLayer = {
          type: "image",
          image: img,
          x: layer?.x ?? 0,
          y: layer?.y ?? 0,
          width: layer?.width ?? img.width,
          height: layer?.height ?? img.height,
          visibleInEditor: layer?.visibleInEditor ?? true,
          visibleInExport: layer?.visibleInExport ?? true,
          opacity: layer?.opacity ?? 1,
          name: layer?.name ?? "layer " + rt.editor.layerCnt,
        };
        if (props.index !== undefined) {
          rt.editor.layers.splice(props.index, 0, nLayer);
          return props.index;
        } else {
          rt.editor.layers.push(nLayer);
        }
        return props?.index ?? rt.editor.layers.length - 1;
      } else {
        const nLayer: RTLayer = {
          name: "layer " + rt.editor.layerCnt,
          ...props.layer,
        };
        if (props.index !== undefined) {
          rt.editor.layers.splice(props.index, 0, nLayer);
          return props.index;
        } else {
          rt.editor.layers.push(nLayer);
        }
        return props?.index ?? rt.editor.layers.length - 1;
      }
    } else if (props.layer.type === "canvas") {
      const canvas = document.createElement("canvas");
      canvas.width = rt.editor.canvas.width;
      canvas.height = rt.editor.canvas.height;
      const ctx = canvas.getContext("2d");
      if (!ctx) {
        throw new Error("Failed to get 2d context");
      }
      const nLayer: RTLayer = {
        type: "canvas",
        canvas,
        ctx,
        visibleInEditor: props.layer?.visibleInEditor ?? true,
        visibleInExport: props.layer?.visibleInExport ?? true,
        opacity: props.layer?.opacity ?? 1,
        name: props.layer?.name ?? "layer " + rt.editor.layerCnt,
      };
      if (props.index !== undefined) {
        rt.editor.layers.splice(props.index, 0, nLayer);
        return props.index;
      } else {
        rt.editor.layers.push(nLayer);
      }
      return props?.index ?? rt.editor.layers.length - 1;
    }
    return -1;
  };
