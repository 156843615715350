import { RenderTool } from "../types/types";

export const RTEEnable = (rt: RenderTool) => () => {
  rt.editor.disabled = false;
};

export const RTEDisable = (rt: RenderTool) => () => {
  rt.editor.disabled = true;
  rt.r.render();
};
