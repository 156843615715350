import { createContext } from "react";
import { ParticleController } from "../Particles/types";

interface PCProviderProps {
  children: React.ReactNode | React.ReactNode[];
  p: ParticleController | null | undefined;
  initialized: boolean;
  pSupported: boolean;
  enabled: boolean;
  setEnabled: React.Dispatch<React.SetStateAction<boolean>>;
}

interface PCProps {
  p: ParticleController | null | undefined;
  initialized: boolean;
  pSupported: boolean;
  enabled: boolean;
  setEnabled: React.Dispatch<React.SetStateAction<boolean>>;
}

export const PCContext = createContext<PCProps>({
  p: null,
  initialized: false,
  pSupported: true,
  enabled: true,
  setEnabled: () => null,
});

export const PCProvider: React.FC<PCProviderProps> = ({
  children,
  p,
  initialized,
  pSupported,
  enabled,
  setEnabled
}) => {
  const value: PCProps = { p: p, initialized, pSupported: pSupported, enabled,setEnabled };

  return <PCContext.Provider value={value}>{children}</PCContext.Provider>;
};
