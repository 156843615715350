import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

interface ContextMenuItem {
  element: JSX.Element | string;
  onClick?: () => Promise<void>;
}

interface UseContextMenu {
  ContextMenu: React.FC;
  onContextMenu: (e: React.MouseEvent, options: ContextMenuItem[]) => void;
}

const useContextMenu: () => UseContextMenu = () => {
  const [open, setOpen] = useState<boolean>(false);
  const [position, setPosition] = useState<{ x: number; y: number }>({
    x: 0,
    y: 0,
  });
  const [options, setOptions] = useState<ContextMenuItem[]>([]);

  const onContextMenu = useCallback(
    (e: React.MouseEvent, options: ContextMenuItem[]) => {
      e.preventDefault();
      e.stopPropagation();
      setOpen(true);
      setPosition({ x: e.clientX, y: e.clientY });
      setOptions(options);
    },
    [open]
  );

  const ContextMenu: React.FC = () => {
    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
      const handleClick = (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        if (ref.current && !ref.current.contains(e.target as Node)) {
          setOpen(false);
        }
      };
      document.addEventListener("click", handleClick);
      return () => {
        document.removeEventListener("click", handleClick);
      };
    }, []);

    const onSubContextMenu = (e: React.MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();
    };

    if (!open) return null;

    return (
      <div
        ref={ref}
        onContextMenu={onSubContextMenu}
        className="context-menu-container"
        style={{
          top: position.y,
          left: position.x,
        }}
      >
        {options?.map((option, i) => {
          const click = async () => {
            await option?.onClick?.();
            setOpen(false);
          };
          if (typeof option.element === "string") {
            return (
              <span
                key={option.element + "-" + i}
                onClick={click}
                className="context-menu-option"
              >
                {option.element}
              </span>
            );
          }
          return option.element;
        })}
      </div>
    );
  };

  return {
    ContextMenu,
    onContextMenu,
  };
};

export default useContextMenu;
