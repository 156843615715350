import React, { useState, useRef, useEffect } from "react";
import { hexToHSV, HSVtoRGB, RGBtoHex } from "../../Utils/colors";
import EyeDropper from "./EyeDropper";

interface ColorPickerProps {
  color: string;
  onChange: (color: string) => void;
}

const ColorPicker: React.FC<ColorPickerProps> = ({ color, onChange }) => {
  const pickerRef = useRef<HTMLDivElement>(null);
  const dragging = useRef<boolean>(false);
  const cRef = useRef(color);

  const hsv = hexToHSV(color);

  useEffect(() => {
    const handleMouseMove = (e: MouseEvent) => {
      if (!dragging.current && e.type === "mousemove") return;
      if (!pickerRef.current) return;
      const cur = hexToHSV(cRef.current);

      const rect = pickerRef.current.getBoundingClientRect();
      const x = Math.min(Math.max(0, e.clientX - rect.left), rect.width);
      const y = Math.min(Math.max(0, e.clientY - rect.top), rect.height);

      const saturation = (x / rect.width) * 100;
      const value = 100 - (y / rect.height) * 100;

      onChange(RGBtoHex(HSVtoRGB({ h: cur.h, s: saturation, v: value })));
      //   setHsv((prev) => ({ ...prev, s: saturation, v: value }));
    };
    const handleMouseDown = (e: MouseEvent) => {
      if (!pickerRef.current) return;
      if (!pickerRef.current.contains(e.target as Node)) return;
      dragging.current = true;
      //   handleMouseMove(e);
    };
    const handleMouseUp = () => {
      dragging.current = false;
    };
    window.addEventListener("mousemove", handleMouseMove);
    window.addEventListener("mousedown", handleMouseDown);
    window.addEventListener("mouseup", handleMouseUp);
    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mousedown", handleMouseDown);
      window.removeEventListener("mouseup", handleMouseUp);
    };
  }, []);

  useEffect(() => {
    cRef.current = color;
  }, [color]);

  return (
    <div className="color-picker">
      <div
        ref={pickerRef}
        className="color-area"
        style={{
          background: `hsl(${hsv.h}, 100%, 50%)`,
        }}
      >
        <div className="white-gradient" />
        <div className="black-gradient" />
        <div
          className="picker-cursor"
          style={{
            left: `${hsv.s}%`,
            top: `${100 - hsv.v}%`,
          }}
        />
      </div>

      <input
        type="range"
        min="0"
        max="360"
        value={hsv.h}
        onChange={(e) =>
          onChange(
            RGBtoHex(
              HSVtoRGB({ s: hsv.s, v: hsv.v, h: parseInt(e.target.value) })
            )
          )
        }
        className="hue-slider"
      />

      <div className="color-info">
        <div className="color-preview" style={{ background: color }} />
        <input
          className="color-text-input"
          type="text"
          onBlur={(e) => onChange(e.target.value)}
          value={color}
          onChange={(e) => onChange(e.target.value)}
        />
        <EyeDropper onColorPick={(c: string) => onChange(c)} />
      </div>

      <style>{`
        .color-picker {
          width: calc(100%);
          border-radius: 8px;
        }

        .color-area {
          position: relative;
          width: calc(100% - 4px);
          height: 150px;
          border-radius: 8px;
          overflow:hidden;
          cursor: crosshair;
          border: 2px solid var(--accent-color);
        }

        .white-gradient {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background: linear-gradient(to right, #fff, transparent);
        }

        .black-gradient {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background: linear-gradient(to bottom, transparent, #000);
        }

        .picker-cursor {
          position: absolute;
          width: 12px;
          height: 12px;
          border: 2px solid white;
          border-radius: 50%;
          transform: translate(-50%, -50%);
          pointer-events: none;
          box-shadow: 0 0 4px rgba(0, 0, 0, 0.5);
        }

        .hue-slider {
          width: 100%;
          height: 12px;
          margin: 8px 0;
          -webkit-appearance: none;
          background: linear-gradient(
            to right,
            #ff0000,
            #ffff00,
            #00ff00,
            #00ffff,
            #0000ff,
            #ff00ff,
            #ff0000
          );
          border-radius: 6px;
        }

        .hue-slider::-webkit-slider-thumb {
          -webkit-appearance: none;
          width: 16px;
          height: 16px;
          border-radius: 50%;
          background: white;
          border: 2px solid #ddd;
          cursor: pointer;
        }

        .color-info {
          display: flex;
          align-items: center;
          gap: 8px;
        }

        .color-preview {
          width: 32px;
          height: 32px;
          border-radius: 4px;
          border: 2px solid #ddd;
        }

       .color-text-input {
          width: calc(100% - 96px);
          padding: 8px 8px;
          border: 1px solid var(--accent-color);
          border-radius: 4px;
          font-family: monospace;
          background-color:transparent;
        }
      `}</style>
    </div>
  );
};

export default ColorPicker;
