interface CheckboxProps {
  checked: boolean;
  onChange: (checked: boolean) => void;
  label: string;
}

export const Checkbox: React.FC<CheckboxProps> = ({
  onChange,
  checked,
  label,
}) => {
  return (
    <div className="flex">
      <input
        className="journal-checkbox"
        onChange={(e) => onChange(e.target.checked)}
        checked={checked}
        type="checkbox"
        id="experimental-checkbox"
      />
      <label className="journal-label" htmlFor="experimental-checkbox">
        {label}
      </label>
    </div>
  );
};
