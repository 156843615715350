import { useContext, useState } from "react";
import Toggle from "../Input/Toggle";
import { PCContext } from "../../Context/PCContext";

const ParticleController = () => {
    const {enabled,setEnabled} = useContext(PCContext);
    return (
        <Toggle checked={enabled} onChange={(v) => setEnabled(v)} />
    );
}

export default ParticleController