import { useContext, useState } from "react";
import { ImageGen } from "../../../Pages/AI/Content/ImageGen";
import { ReactComponent as ImageIcon } from "../../../SVGs/image-regular.svg";
import { ReactComponent as ScissorsIcon } from "../../../SVGs/scissors-solid.svg";
import { ReactComponent as LayerIcon } from "../../../SVGs/layer-group-solid.svg";
import { ReactComponent as TestIcon } from "../../../SVGs/edit-icon.svg";
import { AiNavButton } from "./AiNavButton";
import { AIGenContext } from "../../../Context/AIGen/AIGenContext";
import { InpaintGen } from "../InpaintSection";
import { LayersSection } from "../layers/LayersSection";
import { AITestPage } from "../../../Pages/AI/Content/TestPage";

export const AiNav: React.FC = () => {
  const { route } = useContext(AIGenContext);
  return (
    <div className="ai-nav-container col-3">
      <div className="ai-nav-elements">
        <AiNavButton route="image">
          <ImageIcon />
        </AiNavButton>
        <AiNavButton route="impaint">
          <ScissorsIcon />
        </AiNavButton>
        <AiNavButton route="layers">
          <LayerIcon />
        </AiNavButton>
        <AiNavButton route="test">
          <TestIcon />
        </AiNavButton>
      </div>

      {route === "image" && <ImageGen />}
      {route === "impaint" && <InpaintGen />}
      {route === "layers" && <LayersSection />}
      {route === "test" && <AITestPage />}
    </div>
  );
};
