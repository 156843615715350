import { ReactComponent as EyeDropperIcon } from "../../SVGs/eye-dropper-solid.svg";

interface EyeDropperProps {
  onColorPick: (color: string) => void;
}

const EyeDropper: React.FC<EyeDropperProps> = ({ onColorPick }) => {
  const pickColor = async () => {
    try {
      // Check if EyeDropper API is available
      if ("EyeDropper" in window) {
        const eyeDropper = new (window as any).EyeDropper();
        const result = await eyeDropper.open();
        onColorPick(result.sRGBHex);
      } else {
        console.warn("EyeDropper API not supported");
      }
    } catch (e) {
      console.error("Failed to pick color:", e);
    }
  };

  return (
    <button
      onClick={pickColor}
      className="eyedropper-btn"
      title="Pick color from screen"
    >
      <EyeDropperIcon width="20px" height="20px" fill="var(--accent-text)" />

      <style>{`
        .eyedropper-btn {
        width: 32px;
        height: 32px;
          border: none;
          background: transparent;
          cursor: pointer;
          border-radius: 4px;
        }

        .eyedropper-btn:hover {
          background: rgba(0, 0, 0, 0.05);
        }
      `}</style>
    </button>
  );
};

export default EyeDropper;
