import { useCallback, useEffect, useRef, useState } from "react";
import { ParticleController, initialParticleController } from "./types";
import Particles from "./Particles";
import { PCProvider } from "../Context/PCContext";
import Modal from "../Components/Modal/Modal";
import ParticleCompatibilityModal from "../Components/Modal/Modals/ParticleCompatibilty";

interface ParticleContainerProps {
  children: React.ReactNode | React.ReactNode[];
}

const useParticles = process.env?.REACT_APP_USE_PARTICLES ?? false;

export const ParticleContainer: React.FC<ParticleContainerProps> = ({
  children,
}) => {
  const ref = useRef<ParticleController>(initialParticleController);
  const [initialized, setInitialized] = useState(false);
  const [enabled, setEnabled] = useState(localStorage.getItem("particles") === "false" ? false : true);
  const [displayMessage, setDisplayMessage] = useState(false);

  useEffect(() => {
    localStorage.setItem("particles", enabled?.toString() || 'true');
    if (!enabled) {
      setInitialized(false);
      setDisplayMessage(true);
    }
  }, [enabled]);

  const onInitalized = useCallback(() => {
    setInitialized(true);
  }, []);

  const onContinue = useCallback(() => {
    setDisplayMessage(false);
  }, []);

  return (
    <>
      <div style={{ overflow: "hidden", position: "relative" }}>
        <div
          style={{
            position: "fixed",
            top: "0px",
            left: "0px",
            zIndex: -1,
          }}
        >
          {useParticles === "true" && (
            <Particles
              enabled={enabled}
              setEnabled={setEnabled}
              controllerRef={ref}
              onInitalized={onInitalized}
            />
          )}
        </div>
        <ParticleCompatibilityModal
          opened={displayMessage}
          onContinue={onContinue}
        />
      </div>
      <PCProvider
      enabled={enabled}
      setEnabled={setEnabled}
        pSupported={enabled}
        initialized={initialized}
        p={ref.current}
      >
        {children}
      </PCProvider>
    </>
  );
};
