import { promptJson } from "../../../Resources/AI/prompts";

interface AIPromptModalProps {
  onChange: (prompt: string) => void;
  prompt: string;
}

export const AIPromptModal: React.FC<AIPromptModalProps> = ({
  prompt,
  onChange,
}) => {
  return (
    <div className="prompt-modal-container">
      {promptJson.map((v) => {
        return (
          <div className="prompt-modal-section" key={v.name}>
            <h4>{v.name}</h4>
            {v.prompts.map((p) => {
              return (
                <div onClick={() => onChange(`${prompt} ${p}`)} key={p}>
                  {p}
                </div>
              );
            })}
          </div>
        );
      })}
    </div>
  );
};
