import { ImageGen } from "./Content/ImageGen";
import "./AI.css";
import useWinSize from "../../Hooks/useWinSize";
import { AIImagePreviews } from "./Content/ImagePreviews";
import { ImageSearch } from "../../Resources/types";
import { useContext } from "react";
import {
  AIGenContext,
  AIGenContextProvider,
} from "../../Context/AIGen/AIGenContext";
import { AiNav } from "../../Components/AI/nav/AiNav";
import useContextMenu from "../../Hooks/Input/useContextMenu";
import {
  base64ToImage,
  loadImageURL,
} from "../../Components/Rendering/utils/image";
import { downloadBase64Image } from "../../Utils/contextMenu";

const baseURL = process.env.REACT_APP_BASE_URL;

const AIPage: React.FC = () => {
  return (
    <AIGenContextProvider>
      <AIPageWrapped />
    </AIGenContextProvider>
  );
};

const AIPageWrapped: React.FC = () => {
  const { ContextMenu, onContextMenu } = useContextMenu();
  const { isSmall, isDesktop } = useWinSize();
  const { rtRef, setPrompt, setImage, rt } = useContext(AIGenContext);
  return (
    <div className="igen-divider">
      <ContextMenu />
      <AiNav />
      <div className={`${isDesktop ? "col-6" : "col-9"} ai-flex-center`}>
        <canvas
          onContextMenu={(e: React.MouseEvent) =>
            onContextMenu(e, [
              {
                element: "Export Image",
                onClick: async () => {
                  const result = await rt?.editor.render(true);
                  if (result) {
                    downloadBase64Image(result, "image.png");
                  }
                },
              },
              {
                element: "Flatten Layers",
                onClick: async () => {
                  const result = await rt?.editor.render(true);
                  if (rt && result) {
                    rt.editor.layers = [];
                    const res = await base64ToImage(result);
                    rt.editor.addLayer({
                      layer: { type: "image", image: res },
                    });
                    rt.editor.rerender = true;
                    rt.r.render();
                  }
                },
              },
            ])
          }
          style={{
            fontSmooth: "never",
            WebkitFontSmoothing: "none",
            imageRendering: "pixelated",
          }}
          className="igen-canvas"
          width={"100%"}
          height={"100%"}
          ref={rtRef}
        ></canvas>
      </div>
      <div className={`${isDesktop ? "col-3" : "col-12"}`}>
        <AIImagePreviews
          onImageSelect={(image: ImageSearch) => {
            setPrompt(image.additional_data?.prompt || "");
            setImage(baseURL + "/images/" + image.name);
          }}
        />
      </div>
    </div>
  );
  return <ImageGen />;
};

export default AIPage;
