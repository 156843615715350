import { useContext, useEffect, useState } from "react";
import { DataContext } from "../../../Context/DataContext";
import Pagination from "../../../Components/Pagination/Pagination";
import { ImageSearch } from "../../../Resources/types";
import Button from "../../../Components/Button/Button";
import Modal from "../../../Components/Modal/Modal";
import FileUpload from "../../../Components/Input/FileUpload/FileUpload";
import Input from "../../../Components/Input/Input";
import useDebounce from "../../../Hooks/useDebounce";
import useWinSize from "../../../Hooks/useWinSize";
import { ReactComponent as CloudUpload } from "../../../SVGs/cloud-arrow-up-solid.svg";
import useContextMenu from "../../../Hooks/Input/useContextMenu";
import { copyToClipboard, downloadImage } from "../../../Utils/contextMenu";
import { loadImageURL } from "../../../Components/Rendering/utils/image";
import { AIGenContext } from "../../../Context/AIGen/AIGenContext";
const baseURL = process.env.REACT_APP_BASE_URL;

interface AIImagePreviewProps {
  onImageSelect: (img: ImageSearch) => void;
}
export const AIImagePreviews: React.FC<AIImagePreviewProps> = ({
  onImageSelect,
}) => {
  const { fetcher } = useContext(DataContext);
  const { rt } = useContext(AIGenContext);

  const [images, setImages] = useState<ImageSearch[]>([]);
  const [cols, setCols] = useState<ImageSearch[][]>([]);
  const [totalItems, setTotalItems] = useState(0);
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(10);
  const [modalOpened, setModalOpened] = useState(false);
  const [search, setSearch] = useState("");
  const { debounce } = useDebounce(1000);
  const { isTablet, isSmall, isMid, isDesktop } = useWinSize();
  useEffect(() => {
    debounce(() => getImages());
  }, [search]);

  const { ContextMenu, onContextMenu } = useContextMenu();

  const getImages = async () => {
    const results = await fetcher("/search", {
      method: "GET",
      params: {
        search: `images`,
        s: search,
        page: page,
        count: count,
      },
    });
    if (results?.data?.[`images`]?.data) {
      setImages(results.data[`images`].data);
      setTotalItems(results.data[`images`].count);
    }
  };
  const getCols = (num: number) => {
    let cols: ImageSearch[][] = [];
    cols = Array.from({ length: num }, () => []);
    let i = 0;
    images.forEach((image) => {
      cols[i].push(image);
      i = (i + 1) % num;
    });
    return cols;
  };

  const addAsNewLayer = async (url: string) => {
    const res = await loadImageURL(url);

    const num = await rt?.editor.addLayer({
      layer: { type: "image", name: "layer-" + rt.editor.layerCnt, image: res },
    });

    rt?.r.render();
  };

  useEffect(() => {
    if (isDesktop) {
      setCols([images]);
    } else if (isMid) {
      setCols(getCols(3));
    } else if (isSmall) {
      setCols(getCols(2));
    }
  }, [isMid, isSmall, isTablet, search, images]);

  const openModal = () => {
    setModalOpened(true);
  };

  const closeModal = () => {
    setModalOpened(false);
    getImages();
  };

  const handleDelete = async (items: ImageSearch[]) => {
    const results = await fetcher("/upload", {
      method: "DELETE",
      body: {
        type: `images`,
        items: items,
      },
    });
    getImages();
  };

  // const onContextMenu = (e: React.MouseEvent) => {
  //   e.preventDefault();
  //   e.stopPropagation();
  // };

  useEffect(() => {
    getImages();
  }, [page]);

  return (
    <div className="image-gen-preview-container">
      <div className="igen-search">
        <Input
          placeholder="Search Images"
          containerStyle={{ marginTop: "0px" }}
          style={{ width: "100%", marginTop: "0px" }}
          id={`ai-image-search-input`}
          value={search}
          onChange={(val: string) => setSearch(val)}
        />
        <Button style={{ padding: "4px 5px" }} onClick={openModal}>
          <CloudUpload width={20} fill="var(--secondary-text)" />
        </Button>
      </div>
      <Modal
        onClose={() => closeModal()}
        title={`Upload Images`}
        opened={modalOpened}
      >
        <div className="editor-upload-modal-container">
          <FileUpload
            subfile={"uploads"}
            id={`image-uploader`}
            uploadTo={`/upload/image`}
          />
        </div>
      </Modal>
      <ContextMenu />
      <div className="grid" style={{ gap: "12px" }}>
        {cols.map((col, i) => (
          <div
            key={i}
            className={`col-${12 / cols.length} igen-image-col-container`}
          >
            {col.map((image, j) => (
              <img
                onContextMenu={(e) =>
                  onContextMenu(e, [
                    {
                      element: "Download",
                      onClick: () =>
                        downloadImage(
                          baseURL + "/images/" + image.name,
                          image.name.split("/").slice(-1)[0]
                        ),
                    },
                    {
                      element: "Copy URL",
                      onClick: () =>
                        copyToClipboard(baseURL + "/images/" + image.name),
                    },
                    {
                      element: "Add as new layer",
                      onClick: () =>
                        addAsNewLayer(baseURL + "/images/" + image.name),
                    },
                    { element: "Delete", onClick: () => handleDelete([image]) },
                  ])
                }
                onClick={() => onImageSelect(image)}
                key={j + image.name}
                className="igen-preview-image"
                src={baseURL + "/images/" + image.name}
                alt={image.name}
              />
            ))}
          </div>
        ))}
      </div>
      {/* {images.map((image, i) => (
        <img
          onClick={() => onImageSelect(image)}
          key={i + image.name}
          className="igen-preview-image"
          src={baseURL + "/images/" + image.name}
          alt={image.name}
        />
      ))} */}
      <div className="ai-pagination-container">
        <Pagination
          currentPage={page + 1}
          pageSize={count}
          totalCount={totalItems}
          id="pagination-thoughts"
          siblingCount={3}
          onPageChange={(nPage: number) => setPage(nPage - 1)}
        />
      </div>
    </div>
  );
};
