import { RenderTool } from "../types/types";
import { drawRoundedRect } from "../utils/ctx/drawRoundedRect";

export const RTERender =
  (rt: RenderTool) =>
  async (output: boolean = false) => {
    rt.editor.ctx.clearRect(
      0,
      0,
      rt.editor.canvas.width,
      rt.editor.canvas.height
    );
    if (!output) {
      rt.editor.ctx.fillStyle = "#ededed";
      drawRoundedRect(
        rt.editor.ctx,
        0,
        0,
        rt.editor.canvas.width,
        rt.editor.canvas.height,
        20
      );
    }
    await rt.editor.layers.forEach(async (layer) => {
      if (layer?.visibleInEditor === false && output === false) return;
      if (layer?.visibleInExport === false && output === true) return;

      // Save the current context state
      rt.editor.ctx.save();
      if (layer?.opacity ?? 1 < 1.0) {
        rt.editor.ctx.globalAlpha = layer.opacity ?? 1;
      }

      if (layer.type === "image" && typeof layer.image !== "string") {
        rt.editor.ctx.drawImage(
          layer.image,
          layer?.x ?? 0,
          layer?.y ?? 0,
          layer?.width ?? layer.image.width,
          layer?.height ?? layer.image.height
        );
      } else if (layer.type === "text") {
        rt.editor.ctx.fillText(layer.text, layer?.x ?? 0, layer?.y ?? 0);
      } else if (layer.type === "canvas") {
        rt.editor.ctx.drawImage(
          layer.canvas,
          0,
          0,
          rt.editor.canvas.width,
          rt.editor.canvas.height
        );
      }

      rt.editor.ctx.restore();
    });
    if (output) {
      rt.editor.rerender = true;
      return rt.editor.canvas.toDataURL("image/png");
    }
  };
