import { useContext, useState } from "react";
import ColorPicker from "../../Input/ColorPicker";
import { AIGenContext } from "../../../Context/AIGen/AIGenContext";
import { Slider } from "../../Input/Slider";

const PaintSection: React.FC = () => {
  const [color, setColor] = useState("#ff00ff");
  const { rt } = useContext(AIGenContext);

  const [brushSize, setBrushSize] = useState<number>(25);

  const handleColorChange = (c: string) => {
    setColor(c);
    if (rt) {
      rt.editor.tool = "paint";
      rt.editor.toolData.brushColor = c;
    }
  };

  return (
    <div className="igen-section">
      <ColorPicker color={color} onChange={handleColorChange} />
      <Slider
        min={1}
        max={100}
        step={1}
        label={"Brush Size"}
        onChange={(v) => {
          setBrushSize(v);
          if (rt) rt.editor.toolData.brushSize = v;
        }}
        value={brushSize}
      />
    </div>
  );
};

export default PaintSection;
