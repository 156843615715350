interface PromptCategory {
  name: string;
  prompts: string[];
}

export const promptJson: PromptCategory[] = [
  {
    name: "Image Types",
    prompts: [
      "Digital illustration,",
      "Character reference sheet,",
      "Comic Strip,",
      "Comic Book Cover,",
      "Movie Poster,",
      "Blueprint,",
      "Oil Painting,",
      "Matte painting,",
      "Photograph,",
      "Collage,",
      "Concept Art,",
      "Concept sheet,",
      "Pixel Art,",
      "Fantasy map,",
      "Sketch,",
      "Hologram,",
      "Origami art,",
      "Clay,",
      "Plasticine,",
    ],
  },
  {
    name: "Illustration Types",
    prompts: [
      "3D Illustration,",
      "Low Poly,",
      "Comic Book Illustration,",
      "Cartoon Illustration,",
      "Block illustration,",
      "Anime,",
      "Charcoal illustration,",
      "Ink illustration,",
      "Woodcut illustration,",
      "Watercolor illustration,",
      "Pencil Illustration,",
      "Collage Illustration,",
      "Acrylic Illustration,",
      "Line Art,",
      "Psychedelic Illustration,",
      "Fashion Illustration,",
      "Children's Book Illustration,",
      "Caricature,",
      "Chalk Illustration,",
      "Graffiti,",
    ],
  },
  {
    name: "Photo Types",
    prompts: ["Polaroid,", "Portrait,", "Sattelite,"],
  },
  {
    name: "Overall Style Related",
    prompts: [
      "Steampunk,",
      "Clockpunk,",
      "Cyberpunk,",
      "Dieselpunk,",
      "Atompunk,",
      "Rococopunk,",
      "Steelpunk,",
      "Stonepunk,",
      "Oceanpunk,",
      "Elfpunk,",
      "Acidwave,",
      "Weirdcore,",
      "Cottagecore,",
      "Dreamcore,",
      "Vaporwave,",
      "Baroque,",
      "Film noir,",
      "Boho,",
      "Dadaism,",
      "Cubism,",
      "Expressionism,",
      "Fauvism,",
      "Futurism,",
      "Impressionism,",
      "Neo-Impressionism,",
      "Post-Impressionism,",
      "pop art,",
      "Precisionism,",
      "Rococo,",
      "Surrealism,",
      "Street Art,",
      "Suprematism,",
      "Art Deco,",
      "Abstract Expressionism,",
      "Classicism,",
      "Baroque,",
      "Art Nouveau,",
    ],
  },
  {
    name: "Pencil Drawing related Terms",
    prompts: [
      "Cross hatching,",
      "Pointillisim,",
      "Carbon Pencil,",
      "Ink and Paint,",
    ],
  },
  {
    name: "Professional Website References",
    prompts: [
      "Trending on",
      "Art Station,",
      "National geographic,",
      "Dribbble,",
      "Behance,",
      "Deviantart,",
      "CG Society,",
      "Adobe,",
    ],
  },
  {
    name: "Image Quality Related",
    prompts: [
      "4K,",
      "8K,",
      "64K,",
      "Detailed,",
      "Highly Detailed,",
      "High resolution,",
      "Hyper detailed,",
      "HDR,",
      "UHD,",
      "Professional,",
      "Golden Ratio,",
    ],
  },
  {
    name: "Lighting",
    prompts: [
      "Studio lighting,",
      "Soft light,",
      "Neon lighting,",
      "Purple neon lighting,",
      "Ambient light,",
      "Ring light,",
      "Volumetric light,",
      "Natural light,",
      "Sun light,",
      "Sunrays,",
      "Sun rays coming through window,",
      "Nostalgic lighting,",
    ],
  },
  {
    name: "Color Descriptions",
    prompts: [
      "Fantasy vivid colors,",
      "Vivid colors,",
      "Bright colors,",
      "Sepia,",
      "Dark colors,",
      "Pastel colors,",
      "Monochromatic,",
      "Black & White,",
      "Color Splash,",
    ],
  },
  {
    name: "Render, Animation, and 3D related",
    prompts: [
      "Octane render,",
      "Cinematic,",
      "Low Poly,",
      "Isometric assets,",
      "Unreal Engine,",
      "Unity Engine,",
      "Quantum wavetracing,",
      "Polarizing filter,",
    ],
  },
  {
    name: "camera shot type",
    prompts: [
      "Long shot,",
      "Closeup,",
      "POV,",
      "Medium Shot,",
      "Closeup,",
      "Extreme Closeup,",
      "Panoramic,",
    ],
  },
  {
    name: "Camera Lens, Aperture, and Focus",
    prompts: [
      "70mm,",
      "35mm,",
      "135mm,",
      "300mm,",
      "800mm,",
      "Short telephoto,",
      "Super telephoto,",
      "Medium telephoto,",
      "Macro,",
      "Wide angle,",
      "Fish-eye,",
      "Bokeh,",
      "Sharp Focus,",
    ],
  },
  {
    name: "Camera angle, Camera view",
    prompts: [
      "Front,",
      "Side,",
      "Back,",
      "High angle,",
      "Low angle,",
      "Overhead,",
    ],
  },
];
