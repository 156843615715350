import { RenderTool } from "../types/types";

export const mouseBounds = (rt: RenderTool) => {
  if (rt.camera.x < 0) {
    rt.camera.x = 0;
  } else if (rt.camera.x > rt.editor.canvas.width) {
    rt.camera.x = rt.editor.canvas.width;
  }
  if (rt.camera.y < 0) {
    rt.camera.y = 0;
  } else if (rt.camera.y > rt.editor.canvas.height) {
    rt.camera.y = rt.editor.canvas.height;
  }
};
